.ReminderWrapper {
  position: fixed;
  width: 100%;
  top: 0;
  z-index: 999;
}

.Reminder.container {
  position: relative;
  padding-top: 0;
  top: 120px;
}

.Reminder .Bookmark {
  position: absolute;
  top: -110px;
  right: 0;
  height: 210px;
  background-image: url(../styles/images/bookmark.png);
  background-repeat: no-repeat;
  background-size: 100% 210px;
  padding: 1.5em;
  padding-top: 3em;
  padding-bottom: 3.7em;
  color: white;
  text-align: center;
  transition: all 0.2s ease 0s;

  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
}

@media only screen and (min-width: 768px) {
  .ReminderWrapper.scrolled .Reminder .Bookmark {
    /*60px is the scrolled menubar height*/
    top: calc(-110px - 60px);
  }

  .ReminderWrapper.scrolled .Reminder.inactive .Bookmark {
    /*60px is the scrolled menubar height*/
    top: calc(-210px - 120px);
  }

  .ReminderWrapper.scrolled .Reminder .Bookmark:hover {
    /*60px is the scrolled menubar height*/
    top: calc(-20px - 60px);
  }
}

@media only screen and (max-width: 767px) {
  .ReminderWrapper {
    /* We don't want it to scroll with the page because it takes too much space. */
    position: absolute;
  }
}

.Reminder .Bookmark a {
  color: white;
}

.Reminder.inactive .Bookmark {
  top: -210px;
}

.Reminder .Bookmark .name {
  font-weight: bold;
}

.Reminder .Bookmark .hidden {
  max-width: 150px;
}

.Reminder .Bookmark:hover {
  top: -20px;
}
