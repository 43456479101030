.Footer {
  background-color: black;
  padding-bottom: 1rem;
  color: white;
  font-weight: lighter;
  box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.3);
}

.Footer a,
.Footer a:hover {
  text-decoration: none;
}

.Footer .Contact a,
.Footer a:hover {
  color: #009bdd !important;
}

.Footer .Logo {
  margin-top: 0.2rem;
  margin-bottom: -1rem;
  width: 100%;
  max-width: 220px !important;
}

.Footer .Partner {
  width: 100%;
  max-width: 220px !important;
}

.Footer .ui.inverted.list .item .header {
  text-transform: uppercase;
  margin-bottom: 0.5em;
  color: white;
}

.Footer .ui.inverted.list .item {
  text-transform: uppercase;
  margin-bottom: 0.1em;
  color: #ddd;
}

#main {
  min-height: calc(100vh + 1px);
  padding-bottom: 500px;
}

#footer {
  position: relative;
  margin-top: -500px; /* negative value of footer height */
  height: 500px;
  clear: both;
}

.Footer {
  min-height: 500px;
}

.Footer .ui.mini.images .ui.image {
  height: 35px;
}

@media only screen and (max-width: 767px) {
  .not-mobile,
  .DesktopFooter {
    display: none !important;
  }
}

@media only screen and (min-width: 768px) {
  .MobileFooter {
    display: none !important;
  }
}
