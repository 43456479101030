.HeaderBar {
  padding: 0em 0em 2em 0em;
}

.HeaderBar > .ui.container > h1:first-child {
  padding-top: 2rem;
}

.HeaderBar > .ui.container > h1:last-child {
  padding-bottom: 2rem;
}

.HeaderBar {
  color: white;
}

.HeaderBar h1.ui.header {
  font-size: 4rem;
  font-weight: normal;
}

.HeaderBar > .ui.container > div:first-child {
  padding: 2em 0 2em 0;
}

.HeaderBar h2.ui.header {
  font-size: 1.5rem;
  font-weight: normal;
  padding-top: 17px;
  position: relative;
}

.HeaderBar h2.ui.header:after {
  content: "";
  background: white;
  position: absolute;
  top: 0;
  left: 0;
  height: 3px;
  width: 40px;
}

.thefive {
  font-size: 1.1rem;
  padding: 1.2em 1.6em 1.2em 1.6em;
  background-color: rgba(234, 234, 234, 0.1);
  color: white;
  box-shadow: 0px 0px 2px 0px #666;
  border-radius: 0.28571429rem;
  margin-top: 1.6em;
}

.thefive > .paperclip {
  position: absolute;
  top: 0.7em;
  height: 4em;
}

.thefive > .seal {
  position: absolute;
  right: -20px;
  bottom: -95px;
}

.thefive > .seal > .count {
  font-size: 18px;
}

.thefive > div.seal {
  width: 132px;
  font-weight: bold;
  height: 160px;
  line-height: 14px;
  text-align: center;
  font-size: 10px;
  padding-top: 34px;
  padding-right: 22px;
}

.thefive a {
  color: white;
  text-decoration: underline;
}

@media only screen and (max-width: 989px) {
  .HeaderBar h1.ui.header {
    font-size: 3.2rem;
  }

  .thefive {
    font-size: 1rem;
    padding: 0.8em;
  }
}

@media only screen and (max-width: 767px) {
  .HeaderBar h1.ui.header {
    font-size: 2rem;
  }

  .thefive {
    font-size: 1rem;
    background-color: unset;
    box-shadow: none;
    padding: 0 0 3em 0 !important;
    margin-top: 0 !important;
  }

  .thefive .seal {
    right: 0;
  }

  .thefive > .paperclip {
    display: none;
  }
}
