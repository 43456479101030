.TemplateDetails ul {
  margin-left: 0;
  padding-left: 0;
}

.TemplateDetails .Section .container {
  padding-top: 2em;
}

.TemplateDetails ul {
  list-style: none;
}

.TemplateDetails .HeaderBar a {
  color: #fff;
  font-weight: bold;
}

.TemplateDetails .ui.header.template-name,
.TemplateDetails .HeaderBar h2 {
  font-size: 1.3rem;
  color: #fff;
}

.TemplateDetails .HeaderBar a:hover {
  color: #00b5ad;
  font-weight: bold;
}

.TemplateDetails .HeaderBar li {
  padding-left: 1.4em;
  text-indent: -1.4em;
  padding-bottom: 0.2em;
}

.TemplateDetails li {
  padding-left: 1.4em;
  text-indent: -1.4em;
  padding-bottom: 0.2em;
}

.TemplateDetails li:before {
  content: "\f00c";
  font-family: Icons;
  font-style: normal;
  font-weight: 400;
  text-align: center;
  padding-right: 5px;
}

.TemplateDetails.Contract li:before {
  color: #00b5ad;
}

.TemplateDetails.Fixprice li:before {
  color: #eaa90c;
}

.TemplateDetails .ui.header.template-name {
  font-size: 2.5rem;
}

@media only screen and (max-width: 414px) {
  .TemplateDetails .ui.header.template-name {
    font-size: 1.8rem;
  }
}

.TemplateDetails .details-for-mobile {
  display: none;
}

@media only screen and (max-width: 767px) {
  .TemplateDetails .HeaderBar .ui.image {
    display: none;
  }

  .TemplateDetails .details-for-mobile {
    display: block;
  }

  .TemplateDetails .column p {
    /* Justified text looks shitty on small columns! */
    text-align: left;
  }
}
