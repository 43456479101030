.SamplePageForm {
  margin-left: -13px;
  margin-right: -13px;
  margin-top: -14px;
}

.SamplePageForm > .TemplatePageForm.ui.attached.segment {
  border: 0;
}

.HowItWorks .ui.grid.CatalogOverview {
  padding-top: 1rem;
  padding-bottom: 2rem;
}

.HowItWorks > .Section > .ui.container > .ui.container {
  position: relative;
}

.HowItWorks
  > .Section
  > .ui.container
  > .ui.container
  > .ui.stackable.grid::before {
  content: "";
  position: absolute;
  left: 98px;
  top: 200px;
  bottom: 78px;
  height: calc(100% - 160px);
  width: 4px;
  background: none repeat scroll 0% 0% #2c4e86;
}

.HowItWorks > .Section > .ui.container .three.wide.column > img {
  max-width: 160px;
}

@media only screen and (max-width: 767px) {
  .HowItWorks
    > .Section
    > .ui.container
    > .ui.container
    > .ui.stackable.grid::before {
    left: 50%;
  }

  .HowItWorks > .Section > .ui.container .three.wide.column > img {
    max-width: 160px;
  }
  .HowItWorks > .Section > .ui.container .three.wide.column > img {
    margin-left: auto;
    margin-right: auto;
  }
  .HowItWorks > .Section > .ui.container .ui.segment > .ui.header {
    text-align: center;
  }
}
