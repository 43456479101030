.BreadCrumbs {
  height: 38px;
  background-color: #f3f3f3;
  color: #555;
  z-index: 900; /* HeaderBar has more */
  position: sticky;
  top: 4rem;
  margin-left: -20px;
  margin-right: -20px;
  margin-top: -20px;
  margin-bottom: 20px;
}

@media only screen and (max-width: 767px) {
  .BreadCrumbs + .ui.container {
    padding-top: 14px;
  }
  .BreadCrumbs {
    position: static;
  }
}

.BreadCrumbs > .ui.container {
  padding-top: 0.5em !important;
}
