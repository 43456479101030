.PrimaryCategoryPage .ui.container h1.ui.header:first-child {
  padding-bottom: 1.5em;
}

/* Fix for scrollIntoView with a fixed header, see
     https://stackoverflow.com/questions/13614112/using-scrollintoview-with-a-fixed-position-header
   */
.PrimaryCategoryPage .ui.container > div > div > h2.ui.header {
  padding-top: 6rem;
  margin-top: -6rem;
}

.PrimaryCategoryPage a.ui.card {
  color: #333;
}

@media only screen and (max-width: 621px) {
  .PrimaryCategoryPage .ui.cards > .ui.card {
    width: 100%;
  }
}
