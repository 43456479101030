.Home .grid > a.row {
  color: #333;
}

.Home > .Section:nth-child(3) {
  background-size: cover;
  background-repeat: no-repeat;
  background-attachment: scroll;
  background-clip: border-box;
  background-origin: padding-box;
  transition: background-image 0.2s ease-in-out;
  background-image: url("../../styles/images/homepage-header.jpg");
}

.Home > .Section:nth-child(3) > .ui.container {
  box-shadow: initial;
}

.inverted .ui.accordion .title:not(.ui) {
  color: white;
}
