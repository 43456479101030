.CatalogOverview .ui.list > .item {
  display: flex;
  align-items: baseline;
}

.CatalogOverview .ui.list > .item > .content {
  font-size: 1rem;
}

.CatalogOverview .ui.list > .item > .content.floated {
  flex-grow: 1;
  text-align: right;
}
