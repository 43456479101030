body {
  margin: 0;
  padding: 0;
}

html,
body,
body > #root {
  min-height: 100vh;
}

#main {
  display: flex;
  flex-direction: column;
}

/* move to SUI */
.ui.table th > div {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.ui.table tbody {
  font-size: 0.9rem;
}

.ui[class*="very relaxed"].items > .item:first-child {
  margin-top: 0;
}

.ui[class*="very relaxed"].items > .item:last-child {
  margin-bottom: 0;
}

.FAQ .Section h1.ui.header:not(.attached),
.HowItWorks .Section h1.ui.header:not(.attached),
.Request .Section h1.ui.header:not(.attached),
.ProductsOverview .Section h1.ui.header,
.TemplateDetails h1,
.TemplateDetails h2,
.ui.grid > .row > .column > h1.ui.header:first-child,
.ui.container > h1.ui.header:not(.inverted) {
  font-size: 1.5rem;
  font-weight: normal;
  padding-top: 4px;
  color: #999999;
  position: relative;
}

.FAQ .Section h1.ui.header:not(.attached):after,
.HowItWorks .Section h1.ui.header:not(.attached):after,
.Request .Section h1.ui.header:not(.attached):after,
.ProductsOverview .Section h1.ui.header:after,
.ui.grid > .row > .column > h1.ui.header:first-child:after,
.ui.container > h1.ui.header:not(.inverted):after {
  content: "";
  background: #999999;
  position: absolute;
  top: 0;
  left: 0;
  height: 1px;
  width: 30px;
}

.ui.segment > .ui.header:after {
  content: unset !important;
}

h1.ui.header + h2.ui.header {
  margin-top: -0.2em;
}

/* Bug in semantic? */

.ui.fluid.search > .ui.icon.input {
  width: 100%;
}

.DayPicker:not(.DayPicker--interactionDisabled)
  .DayPicker-Day:not(.DayPicker-Day--disabled):not(.DayPicker-Day--selected):not(.DayPicker-Day--outside):hover,
.DayPicker-Day,
.DayPicker-Day--selected {
  border-radius: 6px !important;
  padding-top: 0.7em !important;
  padding-left: 0.7em !important;
  padding-right: 0.7em !important;
  padding-bottom: 0.4em !important;
}

.DayPicker-NavButton--prev {
  left: 1.5rem;
}

.DayPicker-Caption {
  text-align: center !important;
}

.DayPicker-NavButton {
  top: 0.8rem !important;
  width: 1.9rem !important;
}

.ui.form textarea {
  background-color: transparent !important;
  border-top: 0;
  border-left: 0;
  border-right: 0;
  padding-bottom: 9.5px;
  padding-right: 7px;
  padding-top: 9.5px;
  font-size: 14px;
  min-height: 6em;
  height: 6em;
}

/*
.DayPicker,
.ui.selection.dropdown {
  font-size: 1.14285714em;
}
*/
