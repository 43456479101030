.MenuBar,
.MobileMenuBar {
  background: linear-gradient(to right, #1d2249, #2252a3);
  height: 120px;
  width: 100%;
  z-index: 1000;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.3);
  transition: all 0.2s ease;
}

.MenuBar {
  position: fixed;
}

#main.scrolled .MenuBar {
  height: 60px;
  background: linear-gradient(to right, #1d2249ff, #2252a3cc);
  box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.5);
}

#main.scrolled .MenuBar .Logo {
  height: 52px;
}

.MenuBar > .ui.container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-right: 0;
}

.MobileMenuBar > .ui.container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding-right: 0;
  padding-left: 0;
}

.ui.menu.full-height,
.ui.container.full-height {
  height: 100%;
}

.MenuBar .LogoLink {
  line-height: 0;
}

.MenuBar .Logo {
  height: 65px;
  transition: all 0.2s ease;
}
.MobileMenuBar .Logo {
  padding-top: 1em;
  height: 65px;
  padding-left: 19px;
}

.MenuBar .ui.menu {
  font-size: 20px;
}

.MobileMenuBar .ui.menu .ui.item.dropdown .menu > .item,
.MenuBar .ui.menu .ui.item.dropdown .menu > .item {
  padding-top: 1em !important;
  padding-bottom: 1em !important;
  font-weight: 700 !important;
}

.MobileMenuBar .ui.item.dropdown .menu > .item .header,
.MenuBar .ui.item.dropdown .menu > .item .header,
.MobileMenuBar .ui.item.dropdown .menu > .item .content,
.MenuBar .ui.item.dropdown .menu > .item .content {
  color: #2c4e86 !important;
}

.MobileMenuBar .ui.item.dropdown .menu > .item .content,
.MenuBar .ui.item.dropdown .menu > .item .content {
  margin-top: 0.5em;
  text-transform: initial;
  font-weight: 400 !important;
}

.MobileMenuBar .ui.menu {
  font-size: 1.1rem;
  justify-content: space-between;
}

.MenuBar .ui.menu .item {
  min-width: 130px;
  min-height: 40px;
  justify-content: center !important;
}
.MobileMenuBar .ui.menu .item {
  padding-left: 4px;
  padding-right: 4px;
}

.MobileMenuBar .ui.menu .item {
  justify-content: center !important;
  flex-grow: 1;
}

.MenuBar .ui.inverted.menu .item.current::after {
  content: "";
  position: absolute;
  left: 1.15em;
  display: inline-block;
  height: 1em;
  width: calc(100% - 2 * 1.15em);
  border-bottom: 0.2em solid white;
  margin-top: 0.5em;
}

.MobileMenuBar .ui.inverted.menu .item.current::after {
  content: "";
  position: absolute;
  left: 1.15em;
  display: inline-block;
  width: calc(100% - 2 * 1.15em);
  border-bottom: 0.15em solid white;
  bottom: 1em;
}

.MobileMenuBar .ui.active.dropdown {
  position: static;
}

.MobileMenuBar .ui.dropdown > .menu {
  position: absolute;
  left: 0;
  top: 120px !important;
}

@media only screen and (max-width: 871px) {
  .MenuBar {
    display: none;
  }

  .MobileMenuBar .item[href="/blog"] {
    display: none !important;
  }
}

@media only screen and (min-width: 872px) {
  .MobileMenuBar {
    display: none;
  }

  /* we only this on the desktop! on mobile, there is no fixed menubar */
  .MobileMenuBar + div > div {
    /* because the menubar is fixed*/
    padding-top: calc(120px + 2em) !important;
    transition: all 0.2s ease;
  }
}
