@media only screen and (max-width: 767px) {
  .ui.buttons.SocialShareButtons {
    flex-direction: column;
    display: inline-flex;
  }

  .ui.buttons.SocialShareButtons > .button {
    width: 100%;
  }

  .ui.buttons.SocialShareButtons > .button:first-child {
    border-radius: 0.28571429rem 0.28571429rem 0px 0px;
  }

  .ui.buttons.SocialShareButtons > .button:last-child {
    margin-bottom: 0px;
    border-radius: 0px 0px 0.28571429rem 0.28571429rem;
  }

  .ui.buttons.SocialShareButtons > .or {
    display: none;
  }
}
