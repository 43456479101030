.Blog .Section img {
  object-fit: cover;
  height: 200px;
  flex-grow: 1;
}

.Blog .Section .ui.image {
  width: 100%;
}

.Blog .Section .SingleBlogPost .ui.header + .ui.image {
  margin-bottom: 1em;
}

/* Post Preview */
.Blog .Section .ui.header + div + .ui.image {
  margin-top: 1em;
  margin-bottom: 1em;
}

.Blog .ui.card > .content p,
.Blog .ui.cards > .card > .content p {
  margin-bottom: 1em;
}

.Blog .ui.card .content {
  flex-grow: 0;
}

.Blog .more-blogs .ui.card .ui.image {
  object-fit: cover;
  object-position: 0 0;
  height: 120px;
}

.Blog .extra.content {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}

.Blog .extra.content > :first-child {
  flex-grow: 1;
  margin-bottom: 0.5em; /* when wrapped */
}

.Blog .ReadMore {
  margin-top: 1em !important;
}

.Blog .ui.one.cards {
  margin-left: -1em;
  margin-right: -1em;
  margin-top: 1em;
  margin-bottom: 1em;
}

.Blog .ui.card {
  text-decoration: none;
}

.Blog .ui.one.cards > .card {
  width: calc(100% - 2em);
  margin-left: 1em;
  margin-right: 1em;
  padding-left: 0;
}

.Blog .content > .description .block-img > img {
  max-width: 100%;
}

@media only screen and (min-width: 768px) {
  .Blog .content > .description .block-img > img {
    padding: 1em 5em 1em 5em;
  }
}
