.ApiDebugInfo {
  position: absolute;
  top: 0;
  right: 0;
  z-index: 9999;
  padding: 10px;
  background-color: purple;
}

.ApiDebugInfo.development {
  background-color: red;
}

.ApiDebugInfo.production {
  display: none;
}

.ApiDebugInfo.test {
  background-color: yellow;
}
