.Section {
  display: flex;
  flex-direction: column;
}

.Section:last-child {
  flex-grow: 1;
}

.Section > .ui.container {
  flex-grow: 1;
  height: 100%;
  display: flex;
  /*min-height: 24rem;*/
  padding-top: 2rem;
  padding-bottom: 2rem;
  box-shadow: 0 2px 3px 0 rgba(0, 0, 0, 0.3);
}

.Section.center > .ui.container {
  align-items: center;
}

.Section.inverted,
.Section.inverted .header,
.Section.inverted .description {
  color: white !important;
}

.Section.top > .ui.container {
  align-items: start;
}

.Home .Section > .ui.container {
  padding-top: 2rem;
  padding-bottom: 2rem;
}

.Admin .Section > .ui.container {
  padding-top: 0.3em;
}

.Section > .ui.container > div {
  width: 100%;
}
